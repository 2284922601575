import React from 'react';
import { styled, theme } from '../../Theme';
import Filter from '../../Search/Models/Filter.interface';
import Option from '../../Search/Models/Option.interface';
import LockIcon from '../Icons/LockIcon';
import { media } from '@glitz/core';

type PropTypes = {
  filters: Filter[];
  filterFunc: (filter?: string) => void;
};

function onClickedFilteringOption(
  option: Option,
  filterFunc: (filter?: string) => void
) {
  filterFunc(option.selected ? undefined : option.value);
}

function FilterOptions({ filters, filterFunc }: PropTypes) {
  return (
    <>
      <FilterContainer>
        {Array.isArray(filters) &&
          filters.map(
            (item, _) =>
              Array.isArray(item.options) &&
              item.options.map((optionItem, index) => (
                <OptionContainer
                  css={
                    optionItem.selected
                      ? {
                          textDecoration: 'underline',
                          textUnderlineOffset: `${theme.spacing(1)}px`,
                        }
                      : {}
                  }
                  key={index + optionItem.text}
                  onClick={() => {
                    onClickedFilteringOption(optionItem, filterFunc);
                  }}
                >
                  <OptionText>{optionItem.text}</OptionText>
                  {optionItem.selected && (
                    <IconContainer>
                      <LockIcon></LockIcon>
                    </IconContainer>
                  )}
                </OptionContainer>
              ))
          )}
      </FilterContainer>
    </>
  );
}

export default FilterOptions;

const IconContainer = styled.div({
  marginLeft: 'auto',
  marginRight: theme.spacing(4),
});

const FilterContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: theme.spacing(60),
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(6),
  ...media(theme.mediaQuery.mediaMaxMedium, {
    width: '100%',
    margin: { xy: theme.none },
    padding: { xy: theme.spacing(4) },
  }),
});

const OptionText = styled.div({
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  letterSpacing: theme.letterSpacing.normalWide,
  lineHeight: theme.lineHeight.normal,
  color: theme.secondaryText,
});

const OptionContainer = styled.div({
  cursor: 'pointer',
  height: theme.spacing(13),
  display: 'flex',
  alignItems: 'center',
  borderBottom: { width: '1px', color: theme.lightGray },
});
