import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function LockIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      height="16px"
      width="16px"
    >
      <g
        id="Design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Desktop---Search---B2B"
          transform="translate(-336.000000, -361.000000)"
          fill="#030303"
          fillRule="nonzero"
        >
          <g id="Group-6" transform="translate(64.000000, 342.000000)">
            <g id="16:16" transform="translate(16.000000, 19.000000)">
              <g
                id="kex--icon/lock-16-copy"
                transform="translate(256.000000, 0.000000)"
              >
                <path
                  d="M14.2928932,2.29289322 C14.6834175,1.90236893 15.3165825,1.90236893 15.7071068,2.29289322 C16.0675907,2.65337718 16.0953203,3.22060824 15.7902954,3.61289944 L15.7071068,3.70710678 L6.20710678,13.2071068 C5.8465356,13.567678 5.27913885,13.5953219 4.88685349,13.2901039 L4.79265133,13.2068648 L0.295729536,8.70686481 C-0.0946611194,8.31620693 -0.0944444885,7.68304199 0.296213394,7.29265133 C0.656820671,6.93229073 1.22406118,6.90475526 1.616248,7.20991436 L1.71042687,7.29313519 L5.499,11.085 L14.2928932,2.29289322 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </styled.Svg>
  );
}

export default styled(LockIcon);
