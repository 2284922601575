import React from 'react';
import { theme, styled } from '../Theme';
import ContentModel from '../Search/Models/ContentModel.interface';
import GridItem from '../Shared/Grid/GridItem';
import { media } from '@glitz/core';
import ContentContainer from '../Shared/ContentContainer/ContentContainer';
import { useKexNavigate } from '../Shared/KexRouter/KexRouter';
import ArrowRightLinkIcon from '../Shared/Icons/ArrowRightLinkIcon';

type ContentResultType = {
  content: ContentModel[];
};

function ContentResult(props: ContentResultType) {
  const kexNavigate = useKexNavigate();

  const onNavigateToContent = (src: string) => {
    src && kexNavigate(src);
  };

  return (
    <>
      {props.content.map((item, i) => (
        <ContentContainer key={i}>
          <GridItem layout={'1/1'}>
            <Root>
              <TextContainer
                data-blocklayout={'1/2'}
                onClick={() => onNavigateToContent(item.src)}
              >
                <Heading>{item.name}</Heading>
                <IntroText>{item.introText}</IntroText>
                {item.src && (
                  <LinkWrapper>
                    <StyledArrowRightIcon />
                  </LinkWrapper>
                )}
              </TextContainer>
            </Root>
          </GridItem>
        </ContentContainer>
      ))}
    </>
  );
}

export default ContentResult;

const Root = styled.div({
  flexWrap: 'wrap',
  display: 'flex',
  width: '100%',
  color: theme.black,
  maxWidth: theme.searchResultContentMaxWidth,
  margin: { x: 'auto' },
});

const TextContainer = styled.div({
  alignSelf: 'center',
  display: 'flex',
  alignItems: 'baseline',
  flexDirection: 'column',
  padding: {
    y: theme.spacing(4),
    x: theme.spacing(4),
  },
  width: '100%',
  border: {
    xy: {
      style: 'solid',
      width: theme.spacing(0.5),
      color: 'transparent',
    },
  },
  ':hover': {
    cursor: 'pointer',
    border: {
      xy: {
        style: 'solid',
        width: theme.spacing(0.5),
        color: theme.thinGray,
      },
    },
  },
});

const Heading = styled.h3({
  font: { size: theme.epsilon, weight: theme.fontWeight.bold },
  color: theme.blueDark,
  letterSpacing: theme.letterSpacing.moreWide,
  lineHeight: theme.lineHeight.none,
  marginBottom: theme.spacing(4),
  ...media(theme.mediaQuery.mediaMaxMedium, {
    fontSize: theme.zeta,
    marginBottom: theme.spacing(2),
  }),
  ...media(theme.mediaQuery.mediaMaxVerySmall, {
    fontSize: theme.epsilon,
  }),
});

const IntroText = styled.p({
  font: { size: theme.gamma, weight: theme.fontWeight.normal },
  lineHeight: theme.lineHeight.tight,
  letterSpacing: theme.letterSpacing.medium,
  marginBottom: theme.spacing(1),
});

const LinkWrapper = styled.div({
  display: 'block',
  marginLeft: 'auto',
  color: theme.blueDark,
});

const StyledArrowRightIcon = styled(ArrowRightLinkIcon, {
  fill: theme.blueDark,
  width: theme.spacing(4),
  height: theme.spacing(3.5),
});
