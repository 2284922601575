import React from 'react';
import { styled, theme } from '../../Theme';
import { pseudo } from '@glitz/core';

type DotsType = {
  color?: string;
  lineHeight?: string;
};

function Dots({ color }: DotsType) {
  return (
    <Wrapper css={{}}>
      <Dot
        css={{
          color: color ? color : theme.primary,
        }}
      />
      <Dot
        css={{
          color: color ? color : theme.primary,
        }}
      />
      <Dot
        css={{
          color: color ? color : theme.primary,
        }}
      />
    </Wrapper>
  );
}

export default Dots;

const Wrapper = styled.div({ display: 'flex' });

const Dot = styled.div({
  margin: { xy: theme.spacing(1) },
  borderRadius: theme.spacing(5),
  backgroundColor: theme.blueDark,
  padding: { xy: theme.spacing(1) },

  animationName: {
    '0%': {
      opacity: '.2',
    },
    '20%': {
      opacity: '1',
    },
    '100%': {
      opacity: '.2',
    },
  },
  animationDuration: theme.timings.sevenFifths,
  animationIterationCount: 'infinite',
  animationFillMode: 'both',
  ...pseudo(':nth-child(2)', {
    animationDelay: theme.timings.oneFifth,
  }),
  ...pseudo(':nth-child(3)', {
    animationDelay: theme.timings.twoFifths,
  }),
});
