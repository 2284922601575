import React, { useState, useEffect } from 'react';
import SearchPageModel from './Models/SearchPageModel.interface';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import {
  SearchTypes,
  InitSearchResult,
  GetSearchResult,
  GetFilteredSearchResult,
} from './Search';
import { styled, theme } from '../Theme';
import CardGrid from '../Shared/Grid/CardGrid';
import ProductCard from '../ProductCard/ProductCard';
import { ChipsButton } from '../Shared/Button/Button';
import FilterOptions from '../Shared/FilterOptions/FilterOptions';
import ContentResult from '../SearchPage/ContentResult';
import Dots from '../Shared/Button/Dots';
import LoadMoreContainer from '../Shared/LoadMoreContainer/LoadMoreContainer';
import GenericDropDown from '../Shared/ValueDropDown/GenericDropDown';
import { media } from '@glitz/core';
import { mediaQuery } from '../Theme/mediaQueries';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { getUrlParameter, updateUrl } from '../Shared/Common/Helpers';
import IProductCardModel from '../ProductCard/Models/ProductCardModel.interface';
import { EventDispatcher, ON_SEARCH } from '../Shared/Common/EventDispatcher';
import useMedia from '../Shared/Hooks/useMedia';
import { StyleOrStyleArray } from '@glitz/type';
import ContentDivider from '../Shared/Divider/Divider';
import { useUserStateData } from '../Shared/UserContextProvider/UserContextProvider';
import { ListIcon, GridIcon } from '../Shared/Icons';
import ContentContainer from '../Shared/ContentContainer/ContentContainer';
import LeftNavigationLayout from '../LeftNavigationLayout/LeftNavigationLayout';
import Translate, { translate } from '../Shared/Common/Translate';
import Breadcrumbs from '../Shared/Breadcrumb/Breadcrumb';
import ProductVariationModel from '../ProductCard/Models/ProductVariationModel.interface';
import ProductList from '../CategoryPage/ProductList';
import { useKexNavigate } from '../Shared/KexRouter/KexRouter';
import { pushDataLayer } from '../../Features/utils/dataLayer.lib';

const TAKE = 12;

enum DisplayMode {
  List = 'List',
  Grid = 'Grid',
}

function SearchPage() {
  const {
    translations: {
      'searchPage/search': searchLabel,
      'searchPage/products': products,
      'searchPage/content': content,
      'searchPage/filters': filterLabel,
      'searchPage/counter': counter,
      'searchPage/loadMore': loadMore,
      'categoryPage/list': list,
      'categoryPage/grid': grid,
    },
    staticPages: { searchPage },
    siteRoute,
  } = useAppSettingsData();
  const { authenticated } = useUserStateData();
  const { pageTitle, seoTitle, zeroHitsText } = useCurrentPage<
    SearchPageModel
  >();
  const [currentFilter, setCurrentFilter] = useState<string>('');
  const [displayMode, setDisplayMode] = useState<string>(
    getUrlParameter('display') || DisplayMode.Grid
  );
  const { commerce } = useAppSettingsData();
  const kexNavigate = useKexNavigate();

  const changeDisplayMode = (displayMode: DisplayMode) => {
    setDisplayMode(displayMode);
    const url = `${searchPage}?searchQuery=${query}&display=${displayMode}&items=${itemsToTake}${
      currentFilter ? `&topcategory=${currentFilter}` : ''
    }`;
    updateUrl(pageTitle, url);
  };

  const [activeTab, setActiveTab] = useState<string>(SearchTypes.Products);

  const [isSearching, setIsSearching] = useState<boolean>(false);
  const isMobile = useMedia(mediaQuery.mediaMaxLarge);

  const onSearch = (value: boolean) => {
    setIsSearching(value);
  };

  useEffect(() => {
    EventDispatcher.subscribe(ON_SEARCH, onSearch);
    return () => {
      EventDispatcher.unsubscribe(ON_SEARCH, onSearch);
    };
  });

  var query = getUrlParameter('searchQuery');
  var categoryFilter = getUrlParameter('topcategory');

  var itemsToTake = getUrlParameter('items') || TAKE.toString();
  const result = InitSearchResult(query, categoryFilter, itemsToTake);

  const hits =
    result?.productSearchResultCards?.availableItems +
    result?.contentSearchResult?.availableItems;

  const searchResults =
    activeTab === SearchTypes.Products
      ? result.productSearchResultCards
      : result.contentSearchResult;

  const loaded =
    (searchResults?.items.length / searchResults?.availableItems) * 100;
  const counterText = searchResults
    ? counter
        .replace('{0}', searchResults?.items?.length.toString())
        .replace('{1}', searchResults?.availableItems.toString())
    : '';

  useEffect(() => {
    // eslint-disable-next-line
    query = getUrlParameter('searchQuery');
    // eslint-disable-next-line
    itemsToTake = getUrlParameter('items');
    const currentActiveTab = getUrlParameter('tab') as string;
    if (currentActiveTab) {
      setActiveTab(currentActiveTab);
    }

    // tracking for chip button search in search suggestions
    if (
      result.productSearchResultCards?.availableItems ||
      result.contentSearchResult?.availableItems
    ) {
      pushDataLayer({
        event: 'srpSearch',
        searchTerm: query,
        productResult: result.productSearchResultCards?.availableItems,
        contentResult: result.contentSearchResult?.availableItems,
      });
    }
  }, [result.productSearchResultCards, result.contentSearchResult]);

  useEffect(() => {
    const productsHit = result?.productSearchResultCards?.availableItems;

    // redirect to product page if only one product is found
    if (productsHit === 1) {
      const product = result?.productSearchResultCards?.items[0];
      product && kexNavigate(product.url);
    }
  }, [result, kexNavigate]);

  useEffect(() => {
    switch (activeTab) {
      case SearchTypes.Content:
        if (
          result.contentSearchResult?.availableItems === 0 &&
          result.productSearchResultCards?.availableItems !== 0
        ) {
          setActiveTab(SearchTypes.Products);
        }
        break;
      case SearchTypes.Products:
        if (
          result.productSearchResultCards?.availableItems === 0 &&
          result.contentSearchResult?.availableItems !== 0
        ) {
          setActiveTab(SearchTypes.Content);
        }
        break;
      case SearchTypes.Other:
        setActiveTab(SearchTypes.Other);
        break;
      default:
        break;
    }
  }, [result, activeTab, setActiveTab]);

  const contentSwitch = () => {
    switch (activeTab) {
      case SearchTypes.Products:
        if (!authenticated || displayMode === DisplayMode.Grid) {
          return (
            <CardGrid maxWidth={theme.contentMaxWidth}>
              {result?.productSearchResultCards?.items.map(
                (product: IProductCardModel, index: number) => (
                  <ProductCard key={index + product.code} item={product} />
                )
              )}
            </CardGrid>
          );
        }

        return (
          <ContentContainer>
            {result?.productSearchResultVariations?.items &&
              result?.productSearchResultVariations?.items.map(
                (product: ProductVariationModel) => (
                  <ProductList key={product.name} item={product} />
                )
              )}
          </ContentContainer>
        );
      case SearchTypes.Content:
        return (
          <>
            {result?.contentSearchResult?.items && (
              <ContentResult content={result?.contentSearchResult?.items} />
            )}
          </>
        );
    }
  };

  const disableButton = () => {
    switch (activeTab) {
      case SearchTypes.Products:
        if (
          result?.productSearchResultCards?.items.length ===
          result?.productSearchResultCards?.availableItems
        ) {
          return true;
        }
        break;
      case SearchTypes.Content:
        if (
          result?.contentSearchResult?.items.length ===
          result?.contentSearchResult?.availableItems
        ) {
          return true;
        }
        break;
    }
    return false;
  };

  const fetchFilteredSearchResults = (filter: string = '') => {
    if (!query) return;
    setCurrentFilter(filter);
    GetFilteredSearchResult(searchPage, query, result, filter);
    //update URL
    const url = `${searchPage}?searchQuery=${query}${
      filter.length > 0 ? `&topcategory=${filter}` : ''
    }`;
    updateUrl(seoTitle || pageTitle, url);
  };

  const fetchSearchResults = async (filter: string = '') => {
    const loadedItems =
      activeTab === SearchTypes.Products
        ? result?.productSearchResultCards.items.length
        : result?.contentSearchResult.items.length;

    await GetSearchResult(
      searchPage,
      query,
      TAKE,
      loadedItems,
      activeTab,
      result,
      commerce
    );
    const url = `${searchPage}?searchQuery=${query}&display=${displayMode}&items=${itemsToTake}${
      currentFilter ? `&topcategory=${currentFilter}` : ''
    }`;
    updateUrl(seoTitle || pageTitle, url);
  };

  const switchTab = (type: string) => {
    setActiveTab(type);
    updateUrl(
      seoTitle || pageTitle,
      `/${siteRoute}/search/?searchQuery=${query}&tab=${type}`
    );
  };

  const displayLoadMore =
    (activeTab === SearchTypes.Products &&
      result.productSearchResultCards?.availableItems > TAKE) ||
    (activeTab === SearchTypes.Content &&
      result.contentSearchResult?.availableItems);

  const FilterPanel = (
    <FilterOptions
      filters={result.productFilters}
      filterFunc={(filter?: string) => fetchFilteredSearchResults(filter)}
    />
  );

  return (
    <>
      <BreadcrumbWrapper>
        <Breadcrumbs query={translate(searchLabel, [query]).join(' ')} />
      </BreadcrumbWrapper>
      <TopWrapper>
        <TopContainer css={!!!query.length ? { display: 'none' } : {}}>
          {Number.isNaN(hits) ? (
            ''
          ) : isSearching ? (
            <Dots />
          ) : hits > 0 ? (
            <Translate
              translation={searchLabel}
              values={[<Query>{query}</Query>]}
              component={QueryHeader}
            />
          ) : (
            <styled.P>{zeroHitsText}</styled.P>
          )}
          <Divider />
          {hits > 0 && !isMobile ? (
            <ButtonContainer>
              {result.productSearchResultCards?.numberOfItems !== 0 && (
                <ContentButton
                  onClick={() => switchTab(SearchTypes.Products)}
                  isActive={activeTab === SearchTypes.Products}
                >
                  <ButtonTextWrapper
                    css={
                      activeTab === SearchTypes.Products
                        ? activeButtonStyle
                        : inactiveButtonStyle
                    }
                  >{`${products}  - ${result.productSearchResultCards?.availableItems}`}</ButtonTextWrapper>
                </ContentButton>
              )}

              {result.contentSearchResult?.numberOfItems !== 0 && (
                <ContentButton
                  onClick={() => switchTab(SearchTypes.Content)}
                  isActive={activeTab === SearchTypes.Content}
                >
                  <ButtonTextWrapper
                    css={
                      activeTab === SearchTypes.Content
                        ? activeButtonStyle
                        : inactiveButtonStyle
                    }
                  >
                    {`${content} - ${result.contentSearchResult?.availableItems}`}
                  </ButtonTextWrapper>
                </ContentButton>
              )}
            </ButtonContainer>
          ) : (
            isMobile &&
            hits > 0 && (
              <SearchBoxContainer>
                <GenericDropDown
                  values={[products, content]}
                  currentValue={
                    activeTab === SearchTypes.Content ? content : products
                  }
                  setSelectedValue={(selectedValue: any) =>
                    setActiveTab(SearchTypes[selectedValue])
                  }
                />
              </SearchBoxContainer>
            )
          )}
        </TopContainer>
      </TopWrapper>
      <LeftNavigationLayout
        hideBreadcrumbs
        dropDownText={filterLabel}
        {...(activeTab === SearchTypes.Products &&
          result.productFilters?.length > 0 && {
            component: FilterPanel,
          })}
      >
        <MainContainer>
          <ContentWrapper>
            <ContentContainer>
              {authenticated && activeTab === SearchTypes.Products && (
                <DisplayModeToggle>
                  <TextButton
                    css={displayMode === DisplayMode.List ? Selected : {}}
                    onClick={() => changeDisplayMode(DisplayMode.List)}
                  >
                    <ListIcon css={IconCss} />
                    <TextButtonText>{list}</TextButtonText>
                  </TextButton>
                  <Text>/</Text>
                  <TextButton
                    css={displayMode === DisplayMode.Grid ? Selected : {}}
                    onClick={() => changeDisplayMode(DisplayMode.Grid)}
                  >
                    <GridIcon css={IconCss} />
                    <TextButtonText>{grid}</TextButtonText>
                  </TextButton>
                </DisplayModeToggle>
              )}
              {contentSwitch()}
            </ContentContainer>
          </ContentWrapper>
        </MainContainer>
        {displayLoadMore && (
          <LoadWrapper>
            <LoadMoreContainer
              loaded={loaded}
              counterText={counterText}
              loadMore={loadMore}
              disableButton={disableButton()}
              action={fetchSearchResults}
              totalAmount={
                activeTab === SearchTypes.Products
                  ? result.productSearchResultCards?.availableItems
                  : result.contentSearchResult?.availableItems
              }
              TAKE={TAKE}
            />
          </LoadWrapper>
        )}
      </LeftNavigationLayout>
    </>
  );
}

export default SearchPage;

const TopContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.white,
  padding: { top: theme.spacing(4) },
});

const LoadWrapper = styled.div({ padding: { x: theme.spacing(4) } });

const TopWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const Divider = styled(ContentDivider, {
  maxWidth: theme.spacing(16),
  height: theme.spacing(0.5),
  margin: { x: 'auto', y: theme.spacing(4) },
});

const SearchBoxContainer = styled.div({
  padding: { x: theme.spacing(3) },
  width: '100%',
});

const QueryHeader = styled.h1({
  display: 'flex',
  font: { size: theme.beta, weight: theme.fontWeight.bold },
  color: theme.black,
  letterSpacing: theme.letterSpacing.medium,
  textAlign: 'center',
  padding: { x: theme.spacing(3) },
});

const ButtonContainer = styled.div({
  display: 'flex',
});

const ContentButton = styled(ChipsButton, {
  margin: { x: theme.spacing(2) },
  color: theme.blueDark,
});

const BreadcrumbWrapper = styled.div({
  ...media(theme.mediaQuery.mediaMaxLarge, {
    margin: { x: theme.spacing(4) },
  }),
});

const ButtonTextWrapper = styled.p({
  font: {
    size: theme.beta,
    weight: theme.fontWeight.bold,
  },
});

const activeButtonStyle: StyleOrStyleArray = {
  color: theme.white,
};

const Query = styled.div({
  fontWeight: theme.fontWeight.normal,
  marginLeft: theme.spacing(1),
});

const inactiveButtonStyle: StyleOrStyleArray = {
  color: theme.blueDark,
  fontSize: theme.beta,
};

const ContentWrapper = styled.div({
  display: 'flex',
  width: '100%',
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: { y: theme.spacing(8) },
  }),
});

const MainContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  margin: { xy: 'auto' },
  width: '100%',
  ...media(theme.mediaQuery.mediaMaxLarge, {
    padding: { x: theme.spacing(4) },
  }),
});

const IconCss = {
  margin: { left: theme.spacing(2) },
  width: theme.gamma,
  height: theme.epsilon,
  fill: theme.black,
};

const DisplayModeToggle = styled.div({
  display: 'flex',
  justifyContent: 'end',
  padding: { y: theme.spacing(4) },
});

const TextButton = styled.button({
  font: { weight: theme.fontWeight.normal },
  ':hover': {
    textDecoration: 'underline',
  },
  display: 'flex',
  alignItems: 'center',
  ':focus': {
    outline: { style: 'none' },
  },
});

const Selected: StyleOrStyleArray = {
  textDecoration: 'underline',
  fontWeight: theme.fontWeight.bold,
};

const TextButtonText = styled.p({
  margin: { left: theme.spacing(2) },
});
const Text = styled.p({
  margin: { y: 'auto', left: theme.spacing(2) },
});
