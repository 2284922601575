import React, { useState, useRef } from 'react';
import { styled, theme } from '../../Theme';
import { useAppSettingsData } from '../AppSettingsProvider/AppSettingsProvider';
import ChevronIcon from './../Icons/ChevronIcon';
import { StyleOrStyleArray } from '@glitz/type';
type GenericDropDown = {
  values: any[] | undefined;
  currentValue: string;
  setSelectedValue: (value: string) => void;
  isPagination?: boolean;
  label?: string;
  isValid?: boolean;
  returnIndex?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  ghostLabel?: string;
};
export function GenericDropDown({
  values,
  currentValue,
  setSelectedValue,
  isPagination = false,
  label,
  isValid = true,
  returnIndex = false,
  disabled = false,
  defaultValue,
  ghostLabel = '',
}: GenericDropDown) {
  const {
    translations: { 'common/page': pageText, 'common/pageOf': pageOfText },
  } = useAppSettingsData();
  const [showValues, toggleValues] = useState(false);
  const ref = useRef(null);
  const paginationStyle = {
    marginBottom: 0,
    height: '100%',
  };
  const updateValue = (value: string, index: string = '0') => {
    const newValue = returnIndex ? index : value;
    setSelectedValue(newValue);
  };

  const dropDownStyles: StyleOrStyleArray = {
    ...(!isValid && dropdownContainerInvalid),
    ...(isPagination && paginationStyle),
    ...(disabled && {
      border: {
        xy: {
          style: 'solid',
          width: theme.tiny,
          color: theme.white,
        },
      },
    }),
  };

  const currentValueStyle: StyleOrStyleArray = {
    ...(disabled && {
      color: theme.gray,
    }),
  };

  return (
    <Wrapper
      tabIndex={0}
      onBlur={e => {
        if (!e.currentTarget.contains(e.relatedTarget as Element)) {
          toggleValues(false);
        }
      }}
      css={isPagination ? { marginBottom: 0 } : {}}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <DropDownContainer css={dropDownStyles}>
        <ValueFlex
          onClick={() => !disabled && toggleValues(prev => !prev)}
          ref={ref}
        >
          {isPagination ? (
            <CurrentValue>
              {pageText} {currentValue + 1} {pageOfText} {values?.length}
            </CurrentValue>
          ) : !currentValue && ghostLabel ? (
            <CurrentValue css={currentValueStyle}>{ghostLabel}</CurrentValue>
          ) : (
            <CurrentValue css={currentValueStyle}>
              {!!currentValue && !!defaultValue ? defaultValue : currentValue}{' '}
            </CurrentValue>
          )}
          <StyledArrow
            css={{
              transform: showValues ? 'rotate(180deg)' : 'none',
            }}
          />
        </ValueFlex>
        {showValues && (
          <DropDown css={showValues ? {} : { display: 'none' }}>
            {values &&
              values.length > 0 &&
              values.map((value: string, index: number) => (
                <DropDownButton
                  key={value + index}
                  onClick={() => {
                    updateValue(value, index.toString());
                    toggleValues(false);
                  }}
                  css={{
                    opacity: isPagination
                      ? value
                      : value === currentValue
                      ? 0.5
                      : 1,
                  }}
                >
                  {!isPagination ? value : index + 1}
                </DropDownButton>
              ))}
          </DropDown>
        )}
      </DropDownContainer>
    </Wrapper>
  );
}
export default GenericDropDown;
//Dropdown
const Wrapper = styled.div({
  margin: {
    bottom: theme.spacing(4),
  },
});

const DropDownButton = styled.button({
  width: '-webkit-fill-available',
  height: '100%',
  font: { size: theme.alpha },
  textAlign: 'left',
  ':focus': {
    boxShadow: '0 0 0 2px #FFF',
  },
  margin: {
    x: theme.spacing(4),
    y: theme.spacing(3),
  },
});

const DropDownContainer = styled.div({
  width: '100%',
  minHeight: theme.spacing(11),
  position: 'relative',
  height: 'auto',
  display: 'block',
  textAlign: 'left',
  border: {
    xy: { width: theme.tiny, color: theme.black },
  },
});

const ValueFlex = styled.div({
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  minHeight: theme.spacing(11),
  height: '100%',
  alignItems: 'center',
  cursor: 'pointer',
});

const DropDown = styled.div({
  height: 'auto',
  maxHeight: '200px',
  display: 'flex',
  flexDirection: 'column',
  border: {
    xy: { width: theme.tiny, color: theme.black },
  },
  backgroundColor: 'white',
  position: 'absolute',
  width: '100%',
  zIndex: theme.zIndex.DropDown,
  overflow: 'auto',
});

const CurrentValue = styled.span({
  fontSize: theme.gamma,
  lineHeight: theme.spacing(0.5),
  letterSpacing: theme.letterSpacing.nearWide,
  color: theme.black,
  margin: { x: theme.spacing(4) },
});

const StyledArrow = styled(ChevronIcon, {
  height: theme.spacing(4),
  width: theme.spacing(4),
  color: theme.black,
  transition: {
    duration: theme.timings.oneFifth,
    property: 'all',
  },
  margin: { right: theme.spacing(4) },
});

const FormLabel = styled.label({
  font: { size: theme.beta, weight: theme.fontWeight.bold },
  position: 'relative',
  padding: { bottom: theme.spacing(3) },
  backgroundColor: 'inherit',
  display: 'block',
});
const dropdownContainerInvalid: StyleOrStyleArray = {
  border: {
    xy: { width: theme.tiny, color: theme.errorPrimary },
  },
};
